import React, { Fragment } from 'react'

const Footer = () => (
  <Fragment>
    <footer className="footer" style={{ backgroundColor: '#4a4a4a' }}>
      <div className="container">
        <p className="m-0 py-2 text-center text-white">&copy; {(new Date().getFullYear())} NovaFarma</p>
        <p className="m-0 text-center"> <a className="clouddog" href="https://www.clouddog.com.br" title="Ir para a o site da CloudDog">Desenvolvido por CloudDog</a></p>
      </div>
    </footer>
  </Fragment>
)

export default Footer
