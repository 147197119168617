exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-convenio-empresarial-js": () => import("./../../../src/pages/convenio-empresarial.js" /* webpackChunkName: "component---src-pages-convenio-empresarial-js" */),
  "component---src-pages-convenios-parcerias-js": () => import("./../../../src/pages/convenios-parcerias.js" /* webpackChunkName: "component---src-pages-convenios-parcerias-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lojas-js": () => import("./../../../src/pages/lojas.js" /* webpackChunkName: "component---src-pages-lojas-js" */),
  "component---src-pages-ofertas-js": () => import("./../../../src/pages/ofertas.js" /* webpackChunkName: "component---src-pages-ofertas-js" */),
  "component---src-pages-quem-somos-js": () => import("./../../../src/pages/quem-somos.js" /* webpackChunkName: "component---src-pages-quem-somos-js" */),
  "component---src-pages-trabalhe-conosco-js": () => import("./../../../src/pages/trabalhe-conosco.js" /* webpackChunkName: "component---src-pages-trabalhe-conosco-js" */),
  "component---src-templates-contato-detalhe-js": () => import("./../../../src/templates/contato-detalhe.js" /* webpackChunkName: "component---src-templates-contato-detalhe-js" */)
}

