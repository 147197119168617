import React, { Fragment } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Footer from '../footer/footer'
import Header from '../header/header'
import PropTypes from 'prop-types'
import './index.css'

export default function Layout ({ children }) {
  return (
    <Fragment>
      <Header/>
      <div id="padding-global"></div>
      { children }
      <Footer/>
    </Fragment>
  )
}
Layout.propTypes = {
  children: PropTypes.node
}
